import { CALL_API } from '../middleware/api';

import {
  SUBJECT_REQUEST,
  SUBJECT_RECEIVE,
  SUBJECT_ERROR,
} from '../constants/ActionTypes';

export const fetchSubject = () => ({
  [CALL_API]: {
    endpoint: '/subject',
    types: [SUBJECT_REQUEST, SUBJECT_RECEIVE, SUBJECT_ERROR],
  },
});
