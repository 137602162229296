import React from 'react';
import PropTypes from 'prop-types';
import HeaderBar from '../components/Header';

const App = props =>
  <div>
    <HeaderBar />
    <div style={{ backgroundColor: '#fff' }}>
      {props.children}
    </div>
  </div>;

App.propTypes = {
  children: PropTypes.node,
};

export default App;
