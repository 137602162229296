import { CALL_API } from '../middleware/api';

import {
  CLEVEL_ACADEMIC_REQUEST,
  CLEVEL_ACADEMIC_RECEIVE,
  CLEVEL_ACADEMIC_ERROR,
} from '../constants/ActionTypes';

export const fetchClevelDefaultAcademicList = () => ({
  [CALL_API]: {
    endpoint: '/clevels/'+process.env.REACT_APP_AIR_CODE+'/children',
    types: [
      CLEVEL_ACADEMIC_REQUEST,
      CLEVEL_ACADEMIC_RECEIVE,
      CLEVEL_ACADEMIC_ERROR,
    ],
  },
});

export const fetchClevelAcademic = () => ({
  [CALL_API]: {
    endpoint: '/clevels/academic',
    types: [
      CLEVEL_ACADEMIC_REQUEST,
      CLEVEL_ACADEMIC_RECEIVE,
      CLEVEL_ACADEMIC_ERROR,
    ],
  },
});
