import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SearchUserRow from '../components/SearchUserRow';
import Spinner from '../components/Spinner';
import Message from '../components/Message';
import PaginationBar from '../components/PaginationBar';
import {
  searchAllAcademic,
  searchSubject,
  searchClevel,
  searchName,
} from '../actions';

class SearchResultsContainer extends Component {
  // Calls the appropriate API endpoint to get the next page
  //    based on last stored search information
  async onChangePage(page, searchType) {
    if (searchType === 'all') {
      await this.props.searchAllAcademic(page);
    }
    if (searchType === 'subject') {
      await this.props.searchSubject(this.props.searchValue, page);
    }
    if (searchType === 'clevel') {
      await this.props.searchClevel(this.props.searchValue, page);
    }
    if (searchType === 'name') {
      await this.props.searchName(this.props.searchValue, page);
    }
  }

  render() {
    if (this.props.isFetching) {
      return <Spinner />;
    } else if (this.props.searchError) {
      return (
        <Message type="danger" title="Error">
          {this.props.searchError}
        </Message>
      );
    } else if (!this.props.isFetching && this.props.resultsFetched) {
      if (!this.props.noResults) {
        return (
          <div style={{ lineHeight: '1.8em' }}>
            {this.props.searchResults.map((user, index) =>
              <SearchUserRow key={index} index={index} User={user} />
            )}
            {this.props.showPage &&
              <div style={{ textAlign: 'center' }}>
                <PaginationBar
                  totalPages={this.props.totalPages}
                  onChangePage={(page, searchType) =>
                    this.onChangePage(page, searchType)}
                  currentPage={this.props.currentPage}
                  searchType={this.props.searchType}
                />
              </div>}
          </div>
        );
      }
      return (
        <div
          style={{
            color: '#6E7B8B',
            fontWeight: 'bold',
            textAlign: 'center',
            paddingTop: '0.5em',
          }}
        >
          No Results Found
        </div>
      );
    }
    return <div />;
  }
}

SearchResultsContainer.propTypes = {
  searchError: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  resultsFetched: PropTypes.bool.isRequired,
  noResults: PropTypes.bool.isRequired,
  showPage: PropTypes.bool.isRequired,
  searchResults: PropTypes.array.isRequired,
  totalPages: PropTypes.number,
  searchType: PropTypes.string,
  searchValue: PropTypes.string,
  currentPage: PropTypes.number,
};

const mapStateToProps = ({ search }) => ({
  searchError: search.error,
  resultsFetched: search.resultsFetched,
  noResults: search.noResults,
  searchResults: search.searchResults,
  isFetching: search.isFetching,
  showPage: search.showPage,
  totalPages: search.totalPages,
  currentPage: search.currentPage,
  searchType: search.searchType,
  searchValue: search.searchValue,
});

export default connect(mapStateToProps, {
  searchAllAcademic,
  searchSubject,
  searchClevel,
  searchName,
})(SearchResultsContainer);
