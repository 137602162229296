import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import search from './search';
import academicProfile from './academicProfile';
import subject from './subject';
import clevel from './clevel';

export default combineReducers({
  routing,
  search,
  academicProfile,
  subject,
  clevel,
});
