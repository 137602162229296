import React from 'react';
import { Link } from 'react-router';
import {Container, Row, Col, Navbar} from 'react-bootstrap';

const logo = `${process.env.PUBLIC_URL}/images/logo.png`;

const HeaderBar = () =>
  <Navbar
    style={{
      backgroundColor: '#0e586e',
      padding: '1em',
      verticalAlign: 'middle',
    }}
  >
    <Container className="container" style={{ width: '100%' }}>
      <Row className="row">
        <Col lg={2}>
          <Link to="/">
            <img alt="logo.png" src={logo} id="logo" height="70px"/>
          </Link>
        </Col>
        <Col
          lg={3}
          lgOffset={4}
          style={{
            fontColor: '#444',
            fontWeight: 'bold',
            textAlign: 'right',
            float: 'right',
            fontSize: '1em',
            paddingTop: '.2em',
          }}
        >

        </Col>
      </Row>
    </Container>
  </Navbar>;

HeaderBar.propTypes = {};

export default HeaderBar;
