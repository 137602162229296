import { camelizeKeys } from 'humps';

export const CALL_API = 'CALL_API';

export default function api (baseUrl) {
  /**
   * Call the API
   * @param endpoint The url to invoke
   * @param method The HTTP method to use
   * @param params The query params to pass with the request
   * @param body The body to include with the request
   * @param authorization The value to use in the authorization header
   * @returns {*}
   */
  const call = async ({ endpoint, method, params, body, authorization }) => {
    const query = Object.keys(params || {})
      .filter(key => params[key] !== null)
      .map(key => `${key}=${params[key] || null}`)
      .join('&');

    const response = await fetch(`${baseUrl}${endpoint}?${query}`, {
      method: method || 'GET',
      headers: {
        Authorization: authorization,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      credentials: 'include',
    });

    const text = await response.text();

    if (!response.ok) {
      throw text
        ? camelizeKeys(JSON.parse(text))
        : { status: response.status, message: response.statusText };
    }

    return text && camelizeKeys(JSON.parse(text));
  };

  /**
   * Add the API response data to the action
   * @param action The action that invoked the API call
   * @param data The response data from the API
   */
  const actionWith = (action, data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[action.type];
    return finalAction;
  };

  /**
   * Middleware to invoke the API
   */
  return () => next => action => {
    const request = action[CALL_API];
    if (typeof request === 'undefined') {
      return next(action);
    }

    next(actionWith({ type: request.types[0] }));

    return call(request)
      .then(response =>
        next(
          actionWith(action, {
            response,
            type: request.types[1],
          })
        )
      )
      .catch(error =>
        next(
          actionWith(action, {
            type: request.types[2],
            error: error.message,
          })
        )
      );
  };
};
