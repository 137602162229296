import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchAcademicProfile } from '../actions';
import Nav from '../components/Nav';
import Spinner from '../components/Spinner';
import Message from '../components/Message';
import AcademicProfile from '../components/AcademicProfile';
import { Container } from 'react-bootstrap';
const ReactGA = require('react-ga');

class AcademicProfilePage extends Component {
  async componentWillMount() {
    this.logPageView();
    this.props.fetchAcademicProfile(this.props.id);
  }
  logPageView() {
    ReactGA.set({ page: window.location.pathname + window.location.search });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  render() {
    if (this.props.isFetching) {
      return <Spinner />;
    } else if (this.props.error) {
      return (
        <div>
          <Nav content="Academic Staff Profile" />
          <Container style={{ marginTop: '2em' }}>
            <Message type="danger" title="Error">
              {this.props.error}
            </Message>
          </Container>
        </div>
      );
    } else if (this.props.profile === null) {
      return (
        <div>
          <Nav content="Academic Staff Profile" />
          <Container style={{ marginTop: '2em' }}>
            <Message type="warning" title="Not Found">
              Results not found
            </Message>
          </Container>
        </div>
      );
    }
    return (
      <div>
        <Nav content="Academic Staff Profile" />
        <Container>
          <AcademicProfile profile={this.props.profile} />
        </Container>
      </div>
    );
  }
}

AcademicProfilePage.propTypes = {
  error: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  fetchAcademicProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = ({ academicProfile }, ownProps) => ({
  error: academicProfile.error,
  isFetching: academicProfile.isFetching,
  profile: academicProfile.entity,
  id: ownProps.params.id,
});

export default connect(mapStateToProps, {
  fetchAcademicProfile,
})(AcademicProfilePage);
