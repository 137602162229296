import {
  ACADEMIC_PROFILE_REQUEST,
  ACADEMIC_PROFILE_RECEIVE,
  ACADEMIC_PROFILE_ERROR,
} from '../constants/ActionTypes';

const initialState = {
  isFetching: false,
  entity: null,
  error: null,
};

export default function academicProfile(state = initialState, {type, response, error}) {
  switch (type) {
    case ACADEMIC_PROFILE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ACADEMIC_PROFILE_RECEIVE:
      return {
        ...state,
        isFetching: false,
        entity: response ? response : null,
      };
    case ACADEMIC_PROFILE_ERROR:
      return {
        ...state,
        isFetching: false,
        error,
      };
    default:
      return state;
  }
};
