import { createStore, applyMiddleware } from 'redux';
import { browserHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';
import createApi from './middleware/api';
import reducer from './reducers';

const middleware = [thunkMiddleware, routerMiddleware(browserHistory)];

// Code needs to be added here (or somewhere) to set REACT_APP_BASE_URL dynamically
//    as process.env.NODE_ENV changes

if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}
middleware.push(createApi(process.env.REACT_APP_API_URL));

export default function configureStore(initialState) {
  return createStore(reducer, initialState, applyMiddleware(...middleware));
}
