import {
  SUBJECT_REQUEST,
  SUBJECT_RECEIVE,
  SUBJECT_ERROR,
} from '../constants/ActionTypes';

const initialState = {
  isFetching: false,
  entities: [],
  error: null,
};

export default function subject(state = initialState, {type, response, error}) {
  switch (type) {
    case SUBJECT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SUBJECT_RECEIVE:
      return {
        ...state,
        isFetching: false,
        entities: response,
      };
    case SUBJECT_ERROR:
      return {
        ...state,
        isFetching: false,
        error,
      };
    default:
      return state;
  }
};
