import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';

class PaginationBar extends Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(eventKey) {
    this.props.onChangePage(Number(eventKey) - 1, this.props.searchType);
  }

  render() {
    return (
      <>
        <Pagination
          className='justify-content-center'
        >
          {this.props.totalPages > 1 &&
            <>
              <Pagination.First disabled={this.props.currentPage === 0} onClick={() => { this.handleSelect(0)}}/>
              <Pagination.Prev disabled={this.props.currentPage === 0} onClick={() => { this.handleSelect(Math.max(Number(this.props.currentPage), 0))}}/>
            </>
          }

          {this.props.totalPages > 1 && this.props.currentPage - 2 > 0 &&
            <>
              <Pagination.Item onClick={() => { this.handleSelect(0)}}>1</Pagination.Item>
              {this.props.currentPage - 3 > 0 && <Pagination.Ellipsis disabled/>}
            </>
          }

          {this.props.totalPages > 1 &&
            [...Array(this.props.currentPage + 3 >= this.props.totalPages ? 5 + (this.props.currentPage - this.props.totalPages) : 2).keys()].reverse().map((e) => {
              return (this.props.currentPage - e > 0 &&
                <Pagination.Item
                  key={e}
                  onClick={() => { this.handleSelect(this.props.currentPage - e) }}
                >
                  {Number(this.props.currentPage) - e}
                </Pagination.Item>
              )
            })
          }
          
          <Pagination.Item active>{Number(this.props.currentPage) + 1}</Pagination.Item>


          {this.props.totalPages > 1 &&
            [...Array(this.props.currentPage < 2 ? 4 - this.props.currentPage : 2).keys()].map((e) => {
              return (this.props.currentPage + e + 1 < this.props.totalPages &&
                <Pagination.Item
                  key={e}
                  onClick={() => { this.handleSelect(this.props.currentPage + 2 + e) }}
                >
                  {Number(this.props.currentPage) + 2 + e}
                </Pagination.Item>
              )
            })
          }

          {this.props.totalPages > 1 && this.props.currentPage + 3 < this.props.totalPages &&
            <>
              {this.props.currentPage + 4 < this.props.totalPages && <Pagination.Ellipsis disabled/>}
              <Pagination.Item onClick={() => { this.handleSelect(this.props.totalPages)}}>
                {this.props.totalPages}
              </Pagination.Item>
            </>
          }


          {this.props.totalPages > 1 &&
            <>
              <Pagination.Next disabled={this.props.currentPage === this.props.totalPages - 1} onClick={() => { this.handleSelect(Math.min(Number(this.props.currentPage) + 2, this.props.totalPages))}}/>
              <Pagination.Last disabled={this.props.currentPage === this.props.totalPages - 1} onClick={() => { this.handleSelect(this.props.totalPages)}}/>
            </>
          }

        </Pagination>
      </>
    );
  }
}

PaginationBar.propTypes = {
  totalPages: PropTypes.number.isRequired,
  searchType: PropTypes.string,
  onChangePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default PaginationBar;
