import { CALL_API } from '../middleware/api';

import {
  ACADEMIC_PROFILE_REQUEST,
  ACADEMIC_PROFILE_RECEIVE,
  ACADEMIC_PROFILE_ERROR,
} from '../constants/ActionTypes';

export const fetchAcademicProfile = id => ({
  [CALL_API]: {
    endpoint: `/staff/${id}/academic_profile`,
    types: [
      ACADEMIC_PROFILE_REQUEST,
      ACADEMIC_PROFILE_RECEIVE,
      ACADEMIC_PROFILE_ERROR,
    ],
  },
});
