export const SEARCH_INIT = 'SEARCH_INIT';

export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const SEARCH_RECEIVE = 'SEARCH_RECEIVE';

// Used to save the details of the last search so that pagination works with them.
export const SAVE_ALL = 'SAVE_ALL';
export const SAVE_CLEVEL = 'SAVE_CLEVEL';
export const SAVE_NAME = 'SAVE_NAME';
export const SAVE_SUBJECT = 'SAVE_SUBJECT';

export const ACADEMIC_PROFILE_REQUEST = 'ACADEMIC_PROFILE_REQUEST';
export const ACADEMIC_PROFILE_RECEIVE = 'ACADEMIC_PROFILE_RECEIVE';
export const ACADEMIC_PROFILE_ERROR = 'ACADEMIC_PROFILE_ERROR';

export const SUBJECT_REQUEST = 'SUBJECT_REQUEST';
export const SUBJECT_RECEIVE = 'SUBJECT_RECEIVE';
export const SUBJECT_ERROR = 'SUBJECT_ERROR';

export const CLEVEL_ACADEMIC_REQUEST = 'CLEVEL_ACADEMIC_REQUEST';
export const CLEVEL_ACADEMIC_RECEIVE = 'CLEVEL_ACADEMIC_RECEIVE';
export const CLEVEL_ACADEMIC_ERROR = 'CLEVEL_ACADEMIC_ERROR';
