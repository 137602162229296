/* eslint-disable import/prefer-default-export */
export function convertTitle(title) {
  switch (title) {
    case 'PASM':
      return 'Principal Academic Staff Member';
    case 'SASM':
      return 'Senior Academic Staff Member';
    case 'ASM':
      return 'Academic Staff Member';
    case 'FAC':
      return 'Faculty Academic Coordinator';
    case 'ASM - PT':
    case 'ASM - PT - Daily Rate':
      return 'Academic Staff Member - Part Time';
    case 'ASM - PT Permanent':
    case 'ASM - Permanent PT':
      return 'Academic Staff Member - Part Time Permanent';
    case 'NASM - PT':
      return 'Non-Teaching Academic Staff Member - Part Time';
    case 'ASM - Casual':
      return 'Academic Staff Member - Casual';
    case 'SASM - Casual':
      return 'Senior Academic Staff Member - Casual';
    default:
      return title;
  }
}
