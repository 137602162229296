import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'react-bootstrap';
import { convertTitle } from '../util';
import CardHeader from 'react-bootstrap/esm/CardHeader';

const AcademicProfile = ({ profile }) =>
  <div style={{ margin: '2em 0 3em' }}>
    <div
      className="jumbotron motif"
      style={{
        marginBottom: '0.5em',
        padding: '2em',
        background: 'linear-gradient(to right, #0e586e, #59317c)',
        borderRadius: '10px',
      }}
    >
      <Row>
        <Col lg={2} md={3} sm={4} xs={12} style={{alignItems: "center", display: "inline-flex"}}>
          <img
            style={{borderRadius: 5}}
            alt={`${profile.academicUser.firstNameToUse} ${profile.academicUser
              .lastName}`}
            src={`${process.env.REACT_APP_API_URL}/staff/${profile.academicUser
              .staffId}/publicPhoto?size=130`}
          />
        </Col>
        <Col lg={10} md={9} sm={8} xs={12}>
          <h3 style={{
            fontSize: '1.75em',
            fontWeight: 'bold',
          }}>
            {`${profile.academicUser.firstNameToUse}
                ${profile.academicUser.lastName}`}
          </h3>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {[...new Set(profile.academicUser.currentJobTitles)].map((position) =>
              <li key={position}>
                <i className="fa fa-user fa-fw" />
                &nbsp;
                <span>{convertTitle(position)}</span>
              </li>
            )}
          </ul>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {[...new Set(profile.academicUser.occupancies.map(o => o.position.clevel.description))].map((occupancy) =>
              <li key={occupancy}>
                <i className="fa fa-briefcase fa-fw" />
                &nbsp;
                <span>{occupancy}</span>
              </li>
            )}
          </ul>
        </Col>
      </Row>
    </div>
    {profile.subject.length > 0 &&
      <Card style={{ marginBottom: '0.5em' }}>
        <CardHeader>Subject Area</CardHeader>
        <ul>
          {profile.subject.map((subject, index) =>
            <li key={index}>
              <span style={{ whiteSpace: 'pre-wrap' }}>
                {subject.name}
              </span>
            </li>
          )}
        </ul>
      </Card>}
    {profile.location &&
      <Card style={{ marginBottom: '0.5em' }}>
        <CardHeader>Campus Location</CardHeader>
        <span style={{ whiteSpace: 'pre-wrap', padding: '1em 0 1em 2em' }}>
          {profile.location.name}
        </span>
      </Card>}
    {profile.teachingStyle &&
      <Card style={{ marginBottom: '0.5em' }}>
        <CardHeader>Professional profile / Teaching style</CardHeader>
        <span style={{ whiteSpace: 'pre-wrap', padding: '1em 0 1em 2em' }}>
          {profile.teachingStyle}
        </span>
      </Card>}
    {profile.rip.length > 0 &&
      <Card style={{ marginBottom: '0.5em' }}>
        <CardHeader>Research / Interests / Projects</CardHeader>
        <ul>
          {profile.rip.map((rip, index) =>
            <li key={index} style={{ paddingBottom: '0.5em' }}>
              <span style={{ whiteSpace: 'pre-wrap' }}>
                {rip.description}
              </span>
              <br />
              <a rel="noopener" href={rip.url}>
                {rip.url}
              </a>
            </li>
          )}
        </ul>
      </Card>}
    {profile.qualification.length > 0 &&
      <Card style={{ marginBottom: '0.5em' }}>
        <CardHeader>Qualifications</CardHeader>
        <ul>
          {profile.qualification.map((qual, index) =>
            <li key={index}>
              <span style={{ whiteSpace: 'pre-wrap' }}>
                {qual.name}
              </span>
            </li>
          )}
        </ul>
      </Card>}
    {profile.memberships &&
      <Card style={{ marginBottom: '0.5em' }}>
        <CardHeader>Relevant memberships / Professional bodies</CardHeader>
        <span style={{ whiteSpace: 'pre-wrap', padding: '1em 0 1em 2em' }}>
          {profile.memberships}
        </span>
      </Card>}
    {profile.awards.length > 0 &&
      <Card style={{ marginBottom: '0.5em' }}>
        <CardHeader>Awards and Achievements</CardHeader>
        <ul>
          {profile.awards.map((award, index) =>
            <li key={index}>
              <span style={{ whiteSpace: 'pre-wrap' }}>
                {award.name}
              </span>
            </li>
          )}
        </ul>
      </Card>}
  </div>;

AcademicProfile.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default AcademicProfile;
