import React from 'react';
import { Route, IndexRoute } from 'react-router';
import AppContainer from './containers/AppContainer';
import SearchPage from './containers/SearchPage';
import AcademicProfilePage from './containers/AcademicProfilePage';
import NotFound from './components/NotFound';

export default (
  <Route path="/" component={AppContainer}>
    <IndexRoute component={SearchPage} />
    <Route path="/department/:code" component={SearchPage} />
    <Route path="/subject/:id" component={SearchPage} />
    <Route path="/:id/academic_profile" component={AcademicProfilePage} />
    <Route path="*" component={NotFound} />
  </Route>
);
