import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Row, Col, OverlayTrigger } from 'react-bootstrap';
import { convertTitle } from '../util';
import popoverImg from './PopoverImg';

class SearchUserRow extends Component {
  render() {
    return (
      <Row
        style={{
          border: '1px solid #d3dbe8',
          borderRadius: '0.2em',
          marginTop: '0.2em',
          backgroundColor: this.props.index % 2 === 0 ? '#fff' : '#b8d3e3',
          minHeight: '93px'
        }}
      >
        <Col>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            overlay={popoverImg(
              this.props.User.staffId,
              this.props.User.firstNameToUse,
              this.props.User.lastName
            )}
          >
            <img
              style={{
                maxWidth: '75px',
                maxHeight: '75px',
                margin: '0.5em 0',
                borderRadius: 5,
              }}
              src={`${process.env.REACT_APP_API_URL}/staff/${this.props.User
                .staffId}/publicPhoto?size=75`}
              alt={`${this.props.User.firstNameToUse} ${this.props.User
                .lastName}`}
            />
          </OverlayTrigger>
        </Col>
        <Col lg={10} md={11} sm={10} xs={12} style={{ padding: '1em 0' }}>
          <Row>
            <Col lg={4} md={4} xs={12}>
              <b>
                {this.props.User.firstNameToUse} {this.props.User.lastName}
              </b>
            </Col>
            <Col lg={5} md={5} xs={12}>
              <div>
                {[...new Set(this.props.User.currentJobTitles)].map((position, i) =>
                  <div key={i}>
                    <i className="fa fa-user fa-fw" />
                    &nbsp;
                    <span>{convertTitle(position)}</span>{' '}
                  </div>
                )}
              </div>
            </Col>
            <Col lg={3} md={3} xs={12}>
              <div>
                <Link
                  to={`/${this.props.User.staffId}/academic_profile`}
                  target="_blank"
                >
                  <i className="fa fa-search-plus fa-fw" />
                  View Academic Profile
                </Link>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

SearchUserRow.propTypes = {
  User: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default SearchUserRow;
