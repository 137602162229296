import {
  CLEVEL_ACADEMIC_REQUEST,
  CLEVEL_ACADEMIC_RECEIVE,
  CLEVEL_ACADEMIC_ERROR,
} from '../constants/ActionTypes';

const initialState = {
  isFetching: false,
  entities: [],
  error: null,
};

export default function clevel(state = initialState, {type, response, error}) {
  switch (type) {
    case CLEVEL_ACADEMIC_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CLEVEL_ACADEMIC_RECEIVE:
      return {
        ...state,
        isFetching: false,
        entities: response,
      };
    case CLEVEL_ACADEMIC_ERROR:
      return {
        ...state,
        isFetching: false,
        error,
      };
    default:
      return state;
  }
};
