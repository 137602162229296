import React from 'react';
import PropTypes from "prop-types";
import {Container, Nav, Navbar} from "react-bootstrap";
import {Link} from "react-router";

const _Nav = ({ content }) =>
  <Navbar
    style={{
      backgroundColor: '#0e6a7c',
      width: '100%',
      height: '3em',
      textAlign: 'center',
      color: '#fff',
      fontSize: '1em',
      lineHeight: '3em',
      margin: '0 0 2em'
    }}
  >
    <Container className="justify-content-center" id="nav_container" style={{ width: '100%' }}>
    <Nav>
      <Nav.Link style={{color: '#FFFFFF'}} to="/" as={Link}>{content}</Nav.Link>
    </Nav>
    </Container>
  </Navbar>;

Nav.propTypes = {
  content: PropTypes.string.isRequired,
};

export default _Nav;
