import React from 'react';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import configureStore from '../configureStore';
import routes from '../routes';
const ReactGA = require('react-ga');
ReactGA.initialize('UA-2754445-20');

const store = configureStore();
const history = syncHistoryWithStore(browserHistory, store);

const root = () =>
  <Provider store={store}>
    <Router history={history} routes={routes} />
  </Provider>;

export default root;
