import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import {
  Container,
  Col,
  Row,
  Form,
  FormControl,
  Button,
  FormLabel,
  FormSelect,
} from 'react-bootstrap';
import Message from './Message';

class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectId: this.props.currentSubjectId,
      clevelCode: this.props.currentCode,
      name: '',
    };
  }

  resetFormState() {
    browserHistory.push('/');
    this.setState({
      subjectId: '',
      clevelCode: '',
      name: '',
    });
  }

  render() {
    return (
      <Container style={{ marginBottom: '0.5em' }}>
        <Row >
          <Col md={4}>
            <Button
              style={{ width: '100%' }}
              id="viewAllStaffBtn"
              variant="primary"
              type="submit"
              onClick={e => {
                e.preventDefault();
                this.props.onSearchAcademicUserClicked();
              }}
            >
              View all academic staff
            </Button>
          </Col>
          <Col></Col>
          <Col md={4}>
            <Button
              style={{ width: '100%' }}
              onClick={() => {
                this.resetFormState();
                this.props.onSearchClear();
              }}
            >
              Clear All
            </Button>
          </Col>
        </Row>
        <Row style={{ paddingTop: '2em' }}>
          <Col md={4}>
            {this.props.subjectError
              ?
                <Message type="danger" title="Error">
                  {this.props.subjectError}
                </Message>
              :
                <Form>
                  <FormLabel>Search by Subject Area:</FormLabel>
                  <FormSelect
                    style={{ marginBottom: '1em' }}
                    value={this.state.subjectId}
                    onChange={e => {
                      this.setState({ subjectId: e.target.value });
                    }}
                    required
                  >
                    <option value="">Select Subject Area</option>
                    {this.props.subject.map((subject, index) =>
                      <option key={index} value={subject.id}>
                        {subject.name}
                      </option>
                    )}
                  </FormSelect>
                  <Button
                    disabled={this.state.subjectId === ''}
                    style={{ width: '100%' }}
                    type="submit"
                    variant="primary"
                    onClick={e => {
                      e.preventDefault();
                      if (this.state.subjectId !== '') {
                        this.props.onSearchSubjectClicked(this.state.subjectId);
                      }
                    }}
                  >
                    Search
                  </Button>
                </Form>}
          </Col>
          <Col md={4}>
            {this.props.clevelError
              ?
                <Message type="danger" title="Error">
                  {this.props.clevelError}
                </Message>
              :
                <Form>
                  <FormLabel>Search by Department:</FormLabel>
                  <FormSelect
                    style={{ marginBottom: '1em' }}
                    value={this.state.clevelCode}
                    onChange={e => {
                      this.setState({ clevelCode: e.target.value });
                    }}
                    required
                  >
                    <option value="">Select Department</option>
                    {this.props.clevel.map((clevel, index) =>
                      <option key={index} value={clevel.code}>
                        {clevel.description}
                      </option>
                    )}
                  </FormSelect>
                  <Button
                    disabled={this.state.clevelCode === ''}
                    style={{ width: '100%' }}
                    type="submit"
                    variant="primary"
                    onClick={e => {
                      e.preventDefault();
                      if (this.state.clevelCode !== '') {
                        this.props.onSearchClevelClicked(this.state.clevelCode);
                      }
                    }}
                  >
                    Search
                  </Button>
                </Form>}
          </Col>
          <Col md={4}>
            <Form>
              <FormLabel>Search by Name:</FormLabel>
              <FormControl
                style={{ marginBottom: '1em' }}
                componentClass="input"
                placeholder="Input first name, last name or whole name"
                value={this.state.name}
                onChange={e => {
                  this.setState({ name: e.target.value });
                }}
                required
              />
              <Button
                disabled={this.state.name.length < 2}
                style={{ width: '100%' }}
                type="submit"
                variant="primary"
                onClick={e => {
                  e.preventDefault();
                  if (this.state.name.length >= 2) {
                    this.props.onSearchNameClicked(this.state.name);
                  }
                }}
              >
                Search
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

SearchForm.propTypes = {
  currentSubjectId: PropTypes.string.isRequired,
  currentCode: PropTypes.string.isRequired,
  subject: PropTypes.array.isRequired,
  subjectError: PropTypes.string,
  clevel: PropTypes.array.isRequired,
  clevelError: PropTypes.string,
  onSearchSubjectClicked: PropTypes.func.isRequired,
  onSearchAcademicUserClicked: PropTypes.func.isRequired,
  onSearchClevelClicked: PropTypes.func.isRequired,
  onSearchNameClicked: PropTypes.func.isRequired,
  onSearchClear: PropTypes.func.isRequired,
};

export default SearchForm;
