import {
  SEARCH_INIT,
  SEARCH_REQUEST,
  SEARCH_ERROR,
  SEARCH_RECEIVE,
  SAVE_ALL,
  SAVE_CLEVEL,
  SAVE_NAME,
  SAVE_SUBJECT,
} from '../constants/ActionTypes';

const initialState = {
  isFetching: false,
  resultsFetched: false,
  searchResults: [],
  noResults: false,
  error: null,
  showPage: false,
  totalPages: 1,
  currentPage: 0,
  searchType: null,
  searchValue: null,
};

export default function search(state = initialState, {type, response, error, searchValue}) {
  let noResults = false;
  switch (type) {
    case SEARCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        resultsFetched: false,
        noResults: false,
        error: null,
        showPage: false,
        totalPages: 1,
        currentPage: 0,
      };
    case SEARCH_RECEIVE:
      noResults = !(!!response || false);
      return {
        ...state,
        isFetching: false,
        searchResults: response.content
          ? response.content
          : response.length > 0 ? response : [],
        resultsFetched: true,
        showPage: response.totalPages ? response.totalPages > 1 : false,
        noResults,
        totalPages: response.totalPages ? response.totalPages : 1,
        currentPage: response.number ? response.number : 0,
      };
    case SEARCH_ERROR:
      return {
        ...state,
        isFetching: false,
        resultsFetched: false,
        error,
      };
    case SEARCH_INIT:
      return initialState;
    case SAVE_CLEVEL:
      return {
        ...state,
        searchType: 'clevel',
        searchValue,
      };
    case SAVE_NAME:
      return {
        ...state,
        searchType: 'name',
        searchValue,
      };
    case SAVE_SUBJECT:
      return {
        ...state,
        searchType: 'subject',
        searchValue,
      };
    case SAVE_ALL:
      return {
        ...state,
        searchType: 'all',
        searchValue: null,
      };
    default:
      return state;
  }
};
