import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const Message = ({ children, title, type, hidden = false, style }) =>
    (!hidden
    ?
      <div style={style}>
        <Card style={{ textAlign: 'center' }} header={title} variant={type}>
          {children}
        </Card>
      </div>
    : null);

Message.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  type: PropTypes.string,
  hidden: PropTypes.bool,
  style: PropTypes.object,
};

export default Message;
