import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import Nav from '../components/Nav';
import SearchResultsContainer from './SearchResultsContainer';
import SearchForm from '../components/SearchForm';
import {
  fetchSubject,
  fetchClevelAcademic,
  fetchClevelDefaultAcademicList,
  searchAllAcademic,
  searchSubject,
  searchClevel,
  searchName,
  searchInit,
  saveAll,
  saveSubject,
  saveClevel,
  saveName,
} from '../actions';
const ReactGA = require('react-ga');

class SearchPage extends Component {
  componentWillMount() {
    this.props.fetchSubject();
    // This sets the clevel to only show academic departments or a dynamically generated
    // department list of all academic profiles
    if (process.env.REACT_APP_RESTRICT_CLEVEL === 'true') {
      this.props.fetchClevelDefaultAcademicList();
    } else {
      this.props.fetchClevelAcademic();
    }
    if (this.props.code !== '') {
      this.searchClevel(this.props.code);
    }
    if (this.props.subjectId !== '') {
      this.searchSubject(this.props.subjectId);
    } else {
      this.searchAllAcademic();
    }
  }

  logPageView(value) {
    ReactGA.set({ page: window.location.pathname + value });
    ReactGA.pageview(window.location.pathname + value);
  }

  async searchAllAcademic() {
    browserHistory.push('/');
    this.logPageView("all");
    await this.props.saveAll();
    await this.props.searchAllAcademic();
  }

  async searchSubject(subjectId) {
    browserHistory.push('/');
    this.logPageView("subject/" + subjectId);
    await this.props.saveSubject(subjectId);
    await this.props.searchSubject(subjectId);
  }

  async searchClevel(clevelCode) {
    browserHistory.push('/');
    this.logPageView("department/" + clevelCode);
    await this.props.saveClevel(clevelCode);
    await this.props.searchClevel(clevelCode);
  }

  async searchName(name) {
    browserHistory.push('/');
    this.logPageView("name/" + name);
    await this.props.saveName(name);
    await this.props.searchName(name);
  }

  render() {
    return (
      <div style={{ paddingBottom: '1em' }}>
        <Nav content="Ara Academic Staff Profiles" />
        <div style={{ margin: '0 1em', position: 'relative' }}>
          <Container>
            <Row>
              <div
                className="p-4 mb-4 bg-light rounded-3"
                style={{
                  background: 'linear-gradient(to right, #0e586e, #59317c)',
                  //backgroundImage: 'url(/images/pattern-search-form-v2.png)',
                  //backgroundSize: "cover"
                }}
              >
                <SearchForm
                  currentSubjectId={this.props.subjectId}
                  currentCode={this.props.code}
                  subject={this.props.subject}
                  subjectError={this.props.subjectError}
                  onSearchSubjectClicked={subjectId =>
                    this.searchSubject(subjectId)}
                  onSearchClevelClicked={clevelCode =>
                    this.searchClevel(clevelCode)}
                  onSearchAcademicUserClicked={() => this.searchAllAcademic()}
                  onSearchNameClicked={name => this.searchName(name)}
                  onSearchClear={this.props.searchInit}
                  clevel={this.props.clevel}
                  clevelError={this.props.clevelError}
                />
              </div>
            </Row>
            <SearchResultsContainer />
          </Container>
        </div>
      </div>
    );
  }
}

SearchPage.propTypes = {
  code: PropTypes.string.isRequired,
  subjectId: PropTypes.string.isRequired,
  fetchSubject: PropTypes.func.isRequired,
  fetchClevelAcademic: PropTypes.func.isRequired,
  fetchClevelDefaultAcademicList: PropTypes.func.isRequired,
  subject: PropTypes.array.isRequired,
  subjectError: PropTypes.string,
  clevel: PropTypes.array.isRequired,
  clevelError: PropTypes.string,
  searchSubject: PropTypes.func.isRequired,
  searchAllAcademic: PropTypes.func.isRequired,
  searchClevel: PropTypes.func.isRequired,
  searchName: PropTypes.func.isRequired,
  searchInit: PropTypes.func.isRequired,
  saveAll: PropTypes.func.isRequired,
  saveSubject: PropTypes.func.isRequired,
  saveClevel: PropTypes.func.isRequired,
  saveName: PropTypes.func.isRequired,
};

const mapStateToProps = ({ subject, clevel }, ownProps) => ({
  code: ownProps.params.code || '',
  subjectId: ownProps.params.id || '',
  subject: subject.entities,
  subjectError: subject.error,
  clevel: clevel.entities,
  clevelError: clevel.error,
});

export default connect(mapStateToProps, {
  fetchSubject,
  fetchClevelAcademic,
  fetchClevelDefaultAcademicList,
  searchSubject,
  searchAllAcademic,
  searchClevel,
  searchName,
  searchInit,
  saveAll,
  saveSubject,
  saveClevel,
  saveName,
})(SearchPage);
