import { CALL_API } from '../middleware/api';

import {
  SEARCH_INIT,
  SEARCH_REQUEST,
  SEARCH_RECEIVE,
  SEARCH_ERROR,
  SAVE_ALL,
  SAVE_CLEVEL,
  SAVE_NAME,
  SAVE_SUBJECT,
} from '../constants/ActionTypes';

export const searchInit = () => dispatch => {
  dispatch({ type: SEARCH_INIT });
};

export const searchAllAcademic = (page = '0') => ({
  [CALL_API]: {
    endpoint: '/search/academic_staff/all',
    params: { page },
    types: [SEARCH_REQUEST, SEARCH_RECEIVE, SEARCH_ERROR],
  },
});

export const searchSubject = (subjectId, page = '0') => ({
  [CALL_API]: {
    endpoint: `/search/academic_staff/subject/${subjectId}`,
    params: { page },
    types: [SEARCH_REQUEST, SEARCH_RECEIVE, SEARCH_ERROR],
  },
});

export const searchClevel = (clevelCode, page = '0') => ({
  [CALL_API]: {
    endpoint: `/search/academic_staff/clevels/${clevelCode}`,
    params: { page },
    types: [SEARCH_REQUEST, SEARCH_RECEIVE, SEARCH_ERROR],
  },
});

export const searchName = (name, page = '0') => ({
  [CALL_API]: {
    endpoint: `/search/academic_staff/name/${name}`,
    params: { page },
    types: [SEARCH_REQUEST, SEARCH_RECEIVE, SEARCH_ERROR],
  },
});

export const saveClevel = clevelCode => ({
  type: SAVE_CLEVEL,
  searchValue: clevelCode,
});

export const saveName = name => ({
  type: SAVE_NAME,
  searchValue: name,
});

export const saveSubject = subjectId => ({
  type: SAVE_SUBJECT,
  searchValue: subjectId,
});
export const saveAll = () => ({
  type: SAVE_ALL,
  searchValue: null,
});
