import React from 'react';
import { Popover } from 'react-bootstrap';

const popoverImg = (staffId, firstNameToUse, lastName) =>
  <Popover
    id="popover-trigger-hover-focus"
    title={`${firstNameToUse} ${lastName}`}
  >
    <div style={{ width: '210px', height: '210px', marginTop: '0.7em' }}>
      <img
        style={{
          maxWidth: '200px',
          maxHeight: '200px',
          display: 'block',
          margin: 'auto',
        }}
        src={`${process.env
          .REACT_APP_API_URL}/staff/${staffId}/publicPhoto?size=200`}
        alt={`${firstNameToUse} ${lastName}`}
      />
    </div>
  </Popover>;

export default popoverImg;
